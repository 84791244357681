import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/about": [3],
		"/about/humans": [4],
		"/about/insides": [5],
		"/about/privacy": [6],
		"/about/terms": [7],
		"/account": [~8],
		"/admin/odin": [~9],
		"/assistants": [10],
		"/assistants/brigid": [11],
		"/assistants/minerva": [12],
		"/assistants/seshat": [~13],
		"/community": [14],
		"/conversations": [~15],
		"/conversations/create": [~17],
		"/conversations/[id]": [~16],
		"/guides": [18],
		"/library": [~19],
		"/library/documents": [~20],
		"/library/documents/import": [~22],
		"/library/documents/new": [~23],
		"/library/documents/[id]": [~21],
		"/sign-in": [24],
		"/tttest/sentry-example": [25]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';